import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export  const MInputDrop = (props) =>{

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles);
        props.onClick(acceptedFiles);
      }, []);
    
      const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
 
      return (
        <div
          {...getRootProps()}
          style={{
            border: "2px dashed #007BFF",
            borderRadius: "10px",
            padding: "20px",
            textAlign: "center",
            backgroundColor: isDragActive ? "#f0f8ff" : "#fff",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Solte o arquivo aqui...</p>
          ) : (
            <p>{props.title ?? "Arraste e solte arquivos aqui, ou clique para selecionar"}</p>
          )}
        </div>
      );
}