export const recuperarLogo = () => localStorage.getItem("logoCliente");
export const recuperarNomeFantasia = () => localStorage.getItem("nomeFantasia");

export const atualizarAmbiente = (res) => {
    if (res.id == window.cnf.usuario.revendedorId) {
        if (res.logoRevendedor != null && res.NomeFantasia != null) {
            localStorage.setItem("logoCliente", res.logoRevendedor);
            localStorage.setItem("nomeFantasia", res.NomeFantasia);
        }
        else{
            localStorage.removeItem("logoCliente");
            localStorage.removeItem("nomeFantasia");
        }
        setTimeout(()=>{                    
            window.location.reload();
        },2000);
    }
}

export const setarLogo = item =>{
    if (item.logoRevendedor && item.nomeFantasia) {
        localStorage.setItem("logoCliente", item.logoRevendedor);
        localStorage.setItem("nomeFantasia", item.nomeFantasia);
      } else {
        localStorage.removeItem("logoCliente");
        localStorage.removeItem("nomeFantasia");
      }
}